import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Alert,
} from '@mui/material';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';

const auth = getAuth();

function PasswordConfirmationDialog({ open, onClose, onConfirm }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);

  const handleConfirm = async () => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('Utilisateur non authentifié');

      // Vérification du mot de passe actuel
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);

      // Vérification de la correspondance des nouveaux mots de passe
      if (newPassword !== confirmPassword) {
        setError('Les nouveaux mots de passe ne correspondent pas.');
        return;
      }

      // Mise à jour du mot de passe
      await updatePassword(user, newPassword);

      // Réinitialisation des états et fermeture de la boîte de dialogue
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setError(null);
      onConfirm(); // Appelle la fonction après confirmation
      onClose();
    } catch (err) {
      setError(err.message || 'Une erreur est survenue. Veuillez réessayer.');
    }
  };

  const handleCancel = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setError(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Changer le mot de passe</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Veuillez entrer votre mot de passe actuel, puis saisissez et confirmez votre nouveau mot de passe.
        </DialogContentText>
        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        <TextField
          autoFocus
          margin="dense"
          label="Mot de passe actuel"
          type="password"
          fullWidth
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          margin="dense"
          label="Nouveau mot de passe"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          margin="dense"
          label="Confirmer le nouveau mot de passe"
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PasswordConfirmationDialog;
