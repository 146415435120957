import React, { useState } from 'react';
import { Box, IconButton, Button, Popover, useMediaQuery, Chip } from '@mui/material';
import SocialMediaActions from './SocialMediaActions';
import PublishButton from './PublishButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReplayIcon from '@mui/icons-material/Replay';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function CardActionsBottomSection({ isEditing, isPublished, isPublishing, onPublishToFacebook, onSchedule, onRegenerate, selectedDate, isMobile }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const isCompactView = useMediaQuery('(max-width: 845px)');

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  // Conversion du Timestamp en date lisible
  const formattedDate = selectedDate
    ? new Date(selectedDate.seconds * 1000).toLocaleString('fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    : 'Non défini';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: 2,
        width: '100%',
        padding: '0 16px 16px',
      }}
    >
      {/* Section gauche : Sélection des réseaux sociaux et Publier */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {isCompactView ? (
          <>
            {/* Bouton pour ouvrir la sélection des réseaux sociaux en mode mobile/compact */}
            <IconButton color="primary" onClick={handleOpenPopover}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={isPopoverOpen}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box sx={{ p: 2 }}>
                <SocialMediaActions
                  selectedPlatforms={{ facebook: true, instagram: false, twitter: false, linkedin: false }}
                  disabled={isEditing}
                />
              </Box>
            </Popover>
          </>
        ) : (
          // Affichage normal en mode desktop
          <SocialMediaActions
            selectedPlatforms={{ facebook: true, instagram: false, twitter: false, linkedin: false }}
            disabled={isEditing}
          />
        )}
        <PublishButton
          
          isPublishing={isPublishing}
          isPublished={isPublished}
          onClick={onPublishToFacebook}
          disabled={isPublishing || isPublished || isEditing}
        />
      </Box>

      {/* Section droite : Planifier, Régénérer et Date prévue */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {isCompactView ? (
          <>
            {/* Icônes en mode compact */}
            {isEditing && (
              <IconButton
                color="primary"
                onClick={onRegenerate}
                disabled={isPublished || isPublishing || isEditing}
              >
                <ReplayIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {/* Boutons textuels en mode desktop */}
            {isEditing && (
              <Button
                variant="outlined"
                color="primary"
                onClick={onRegenerate}
                disabled={isPublished || isPublishing || isEditing}
              >
                Régénérer
              </Button>
            )}
          </>
        )}
        {/* Affichage de la date prévue dans un Chip pour un style amélioré */}
        <Chip label={`Date prévue : ${formattedDate}`} color="default" variant="outlined" sx={{ ml: 1 }} />
      </Box>
    </Box>
  );
}

export default CardActionsBottomSection;
