import { initializeApp } from 'firebase/app';  
import { getAuth } from 'firebase/auth';  
import { getFirestore } from 'firebase/firestore'; 
import { getStorage } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDDmt-24EcxJJqSDZ3RfkW9IapzY115aos",
  authDomain: "viralist-59357.firebaseapp.com",
  projectId: "viralist-59357",
  storageBucket: "viralist-59357.appspot.com",
  messagingSenderId: "925033861916",
  appId: "1:925033861916:web:28b49a70c966399c2a20d9",
  measurementId: "G-GGMG6FCK0Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);  // 'app' est l'instance Firebase initialisée
const auth = getAuth(app); // Firebase Auth
const db = getFirestore(app); // Firestore
const storage = getStorage(app); // Firebase Storage

// Exporter les services
export { auth, db, storage, onAuthStateChanged };
