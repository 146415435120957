import React, { useState, useEffect, useMemo } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Slider,
  Select,
  MenuItem,
  TextField as MuiTextField,
  Grid,
} from '@mui/material';
import { savePreferences, fetchPreferences } from '../../../../../../services/firebase/preferencesService';

function AICustomizationCard() {
  const [tone, setTone] = useState('');
  const [brandValues, setBrandValues] = useState('');
  const [languageStyle, setLanguageStyle] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [callToActionStyle, setCallToActionStyle] = useState('');
  const [visualStyle, setVisualStyle] = useState('');
  const [humorPersonality, setHumorPersonality] = useState('');
  const [signature, setSignature] = useState('');
  const [textLength, setTextLength] = useState(1);
  const [smileyUsage, setSmileyUsage] = useState(1);
  const [language, setLanguage] = useState('Francais');
  const [linkProductOrService, setLinkProductOrService] = useState('');
  const [hashtags, setHashtag] = useState('');
  const [favoriteThemes, setFavoriteThemes] = useState('');
  const [showMore, setShowMore] = useState(false); // Contrôle des champs supplémentaires

  const textLengthMarks = useMemo(
    () => [
      { value: 0, label: 'Très Court' },
      { value: 1, label: 'Court' },
      { value: 2, label: 'Moyen' },
      { value: 3, label: 'Long' },
      { value: 4, label: 'Très Long' },
    ],
    []
  );

  const smileyUsageMarks = useMemo(
    () => [
      { value: 0, label: 'Aucun' },
      { value: 1, label: 'Modéré' },
      { value: 2, label: 'Beaucoup' },
    ],
    []
  );

  useEffect(() => {
    const loadPreferences = async () => {
      try {
        const preferences = await fetchPreferences();
        if (preferences) {
          setTone(preferences.tone || '');
          setBrandValues(preferences.brandValues || '');
          setLanguageStyle(preferences.languageStyle || '');
          setTargetAudience(preferences.targetAudience || '');
          setCallToActionStyle(preferences.callToActionStyle || '');
          setVisualStyle(preferences.visualStyle || '');
          setHumorPersonality(preferences.humorPersonality || '');
          setSignature(preferences.signature || '');
          const textLengthIndex = textLengthMarks.findIndex(
            (mark) => mark.label.toLowerCase() === preferences.textLength?.toLowerCase()
          );
          setTextLength(textLengthIndex !== -1 ? textLengthIndex : 1);
          const smileyUsageIndex = smileyUsageMarks.findIndex(
            (mark) => mark.label.toLowerCase() === preferences.smileyUsage?.toLowerCase()
          );
          setSmileyUsage(smileyUsageIndex !== -1 ? smileyUsageIndex : 1);
          setLanguage(preferences.language || 'Francais');
          setLinkProductOrService(preferences.linkProductOrService || '');
          setHashtag(preferences.hashtags || '');
          setFavoriteThemes(preferences.favoriteThemes || '');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des préférences :', error);
      }
    };
    loadPreferences();
  }, [textLengthMarks, smileyUsageMarks]);

  const handleSavePreferences = async () => {
    const preferences = {
      textLength: textLengthMarks[textLength].label,
      smileyUsage: smileyUsageMarks[smileyUsage].label,
      tone,
      brandValues,
      languageStyle,
      targetAudience,
      callToActionStyle,
      visualStyle,
      humorPersonality,
      signature,
      language,
      linkProductOrService,
      hashtags,
      favoriteThemes,
    };
    try {
      await savePreferences(preferences);
      console.log('Préférences enregistrées avec succès');
    } catch (error) {
      console.error('Erreur lors de l’enregistrement des préférences :', error);
    }
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <CardContent>
        <Typography variant="h5" fontWeight={600} gutterBottom>
          Personnalisation de l'IA
        </Typography>
        <Grid container spacing={3}>
          {/* Champs toujours visibles */}
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom>Longueur du texte</Typography>
            <Slider
              value={textLength}
              step={1}
              marks={textLengthMarks}
              min={0}
              max={4}
              onChange={(e, value) => setTextLength(Number(value))}
              valueLabelDisplay="off"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom>Utilisation des smileys</Typography>
            <Slider
              value={smileyUsage}
              step={1}
              marks={smileyUsageMarks}
              min={0}
              max={2}
              onChange={(e, value) => setSmileyUsage(Number(value))}
              valueLabelDisplay="off"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom>Ton de communication</Typography>
            <Select
              fullWidth
              value={tone}
              onChange={(e) => setTone(e.target.value)}
              variant="outlined"
            >
              <MenuItem value="">Aucun</MenuItem>
              <MenuItem value="Amical">Amical</MenuItem>
              <MenuItem value="Professionnel">Professionnel</MenuItem>
              <MenuItem value="Humoristique">Humoristique</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom>Style de langage</Typography>
            <Select
              fullWidth
              value={languageStyle}
              onChange={(e) => setLanguageStyle(e.target.value)}
              variant="outlined"
            >
              <MenuItem value="">Aucun</MenuItem>
              <MenuItem value="Décontracté">Décontracté</MenuItem>
              <MenuItem value="Formel">Formel</MenuItem>
              <MenuItem value="Technique">Technique</MenuItem>
            </Select>
          </Grid>

          {/* Champs supplémentaires affichés avec "Voir plus" */}
          {showMore && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography gutterBottom>Style Visuel des Publications</Typography>
                <Select
                  fullWidth
                  value={visualStyle}
                  onChange={(e) => setVisualStyle(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Aucun</MenuItem>
                  <MenuItem value="Minimaliste">Minimaliste</MenuItem>
                  <MenuItem value="Coloré">Coloré</MenuItem>
                  <MenuItem value="Élégant">Élégant</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography gutterBottom>Valeurs de la marque</Typography>
                <Select
                  fullWidth
                  value={brandValues}
                  onChange={(e) => setBrandValues(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Aucun</MenuItem>
                  <MenuItem value="Innovation">Innovation</MenuItem>
                  <MenuItem value="Durabilité">Durabilité</MenuItem>
                  <MenuItem value="Authenticité">Authenticité</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiTextField
                  fullWidth
                  label="Lien avec les Produits et Services"
                  value={linkProductOrService}
                  onChange={(e) => setLinkProductOrService(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiTextField
                  fullWidth
                  label="Hashtags"
                  value={hashtags}
                  onChange={(e) => setHashtag(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiTextField
                  fullWidth
                  label="Thématiques Favorites"
                  value={favoriteThemes}
                  onChange={(e) => setFavoriteThemes(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography gutterBottom>Audience Cible</Typography>
                <Select
                  fullWidth
                  value={targetAudience}
                  onChange={(e) => setTargetAudience(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Aucun</MenuItem>
                  <MenuItem value="Jeunes adultes">Jeunes adultes</MenuItem>
                  <MenuItem value="Professionnels">Professionnels</MenuItem>
                  <MenuItem value="Parents">Parents</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiTextField
                  fullWidth
                  label="Signature"
                  placeholder="Votre ami de Viralist..."
                  value={signature}
                  onChange={(e) => setSignature(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography gutterBottom>Langue</Typography>
                <Select
                  fullWidth
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="Francais">Français</MenuItem>
                  <MenuItem value="Anglais">Anglais</MenuItem>
                  <MenuItem value="Espagnol">Espagnol</MenuItem>
                </Select>
              </Grid>
            </>
          )}
        </Grid>

        {/* Bouton Voir Plus */}
        <Button
          variant="text"
          color="primary"
          onClick={() => setShowMore(!showMore)}
          sx={{ mt: 2 }}
        >
          {showMore ? 'Voir moins' : 'Voir plus'}
        </Button>
      </CardContent>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSavePreferences}
        sx={{ mt: 2 }}
      >
        Enregistrer les Préférences
      </Button>
    </Card>
  );
}

export default AICustomizationCard;
