import React, { useState, useEffect } from 'react';
import { List, Box, Collapse, ListItemButton, ListItemIcon, ListItemText, Avatar } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard'; // Import de l'icône Dashboard
import HistoryIcon from '@mui/icons-material/History';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import MenuItemButton from './MenuItemButton';
import { auth } from '../../../config/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { fetchOwnerInfo } from '../../../services/firebase/ownerService';

const DrawerContent = ({ onLogout, onMenuItemClick }) => {
  const navigate = useNavigate();
  const [profileOpen, setProfileOpen] = useState(false);
  const [userName, setUserName] = useState('Utilisateur');
  const [userPhoto, setUserPhoto] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const ownerInfo = await fetchOwnerInfo(currentUser.uid);
        if (ownerInfo) {
          setUserName(ownerInfo.firstName + " " + ownerInfo.lastName || 'Utilisateur');
          setUserPhoto(ownerInfo.profilePhotoUrl || '');
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleProfileClick = () => {
    setProfileOpen(!profileOpen);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#FFFFFF',
        padding: 0,
        borderRadius: 0,
        overflow: 'hidden',
      }}
    >
      {/* Titre de l'application */}
      <Box
        sx={{
          padding: '8px 0',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: 20,
          color: '#1E1E2F',
        }}
      >
        Visiblia
      </Box>

      {/* Bouton utilisateur dépliant */}
      <Box
        sx={{
          paddingX: '8px',
          marginBottom: '8px',
        }}
      >
        <ListItemButton onClick={handleProfileClick} sx={{ padding: '4px 8px', minHeight: '40px' }}>
          <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
            <Avatar
              src={userPhoto}
              alt={userName}
              sx={{ width: 28, height: 28 }}
            />
          </ListItemIcon>
          <ListItemText
            primary={userName}
            primaryTypographyProps={{
              fontSize: '0.875rem',
              fontWeight: 'bold',
              color: '#1E1E2F',
            }}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          />
          {profileOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={profileOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 2 }}
              onClick={() => {
                navigate('/dashboard/profile');
                onMenuItemClick();
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Mon profil" />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 2 }}
              onClick={() => {
                onLogout();
                onMenuItemClick();
              }}
            >
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Se déconnecter" />
            </ListItemButton>
          </List>
        </Collapse>
      </Box>

      {/* Liste de navigation */}
      <List sx={{ padding: 0 }}>
        <MenuItemButton
          icon={<DashboardIcon />}
          text="Tableau de bord"
          onClick={() => {
            navigate('/dashboard/home');
            onMenuItemClick();
          }}
        />
        <MenuItemButton
          icon={<PostAddIcon />}
          text="Nouvelle publication"
          onClick={() => {
            navigate('/dashboard/new-post');
            onMenuItemClick();
          }}
        />
        <MenuItemButton
          icon={<CalendarMonthIcon />}
          text="Calendrier"
          onClick={() => {
            navigate('/dashboard/calendar');
            onMenuItemClick();
          }}
        />
        <MenuItemButton
          icon={<HistoryIcon />}
          text="Historique"
          onClick={() => {
            navigate('/dashboard/post-history');
            onMenuItemClick();
          }}
        />
      </List>

      {/* Espace pour pousser les éléments en bas */}
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );
};

export default DrawerContent;
