import React, { useState } from 'react';
import { auth, db, storage } from '../../config/firebaseConfig'; // Firebase Storage ajouté
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Card, CardContent, Container, Avatar, IconButton, Alert } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

function SignUpPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [profilePhotoFile, setProfilePhotoFile] = useState(null); // Fichier de la photo de profil
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null); // Aperçu de la photo de profil
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const uploadFile = async (file, path) => {
    const fileRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(fileRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        (error) => reject(error),
        async () => {
          const downloadURL = await getDownloadURL(fileRef);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePhotoPreview(URL.createObjectURL(file)); // Génère un aperçu local
      setProfilePhotoFile(file); // Stocke le fichier pour le télécharger plus tard
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      return;
    }

    try {
      setIsUploading(true);

      // Création de l'utilisateur dans Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      let profilePhotoUrl = '';

      // Téléchargement de la photo de profil, si disponible
      if (profilePhotoFile) {
        profilePhotoUrl = await uploadFile(profilePhotoFile, `profilePhotos/${user.uid}`);
      }

      // Sauvegarde des informations du propriétaire dans Firestore
      const ownerRef = doc(db, 'owners', user.uid);
      await setDoc(ownerRef, {
        firstName,
        lastName,
        email,
        uid: user.uid,
        profilePhotoUrl, // URL de la photo de profil
      });

      console.log('Utilisateur créé avec succès.');
      navigate('/company-form'); // Redirige vers le formulaire de la société
    } catch (error) {
      console.error('Erreur lors de la création du compte:', error);
      setError('Une erreur s\'est produite. Veuillez réessayer.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5, mb: 5 }}>
      <Card sx={{ boxShadow: 3, borderRadius: 4 }}>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" align="center" sx={{ mb: 2 }}>
              Créez votre compte
            </Typography>

            {/* Avatar interactif pour la photo de profil */}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Typography variant="body1" gutterBottom>
                Photo de profil :
              </Typography>
              <IconButton component="label">
                <Avatar
                  src={profilePhotoPreview}
                  sx={{ width: 100, height: 100, cursor: 'pointer' }}
                >
                  <CameraAltIcon />
                </Avatar>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </IconButton>
            </Box>

            <Box component="form" onSubmit={handleSignUp} sx={{ width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="Prénom"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Nom"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresse Email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirmez le mot de passe"
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
                disabled={isUploading}
              >
                {isUploading ? 'Création du compte...' : 'S\'inscrire'}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default SignUpPage;
