import React from 'react';
import { Grid, Paper, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Chart } from 'react-google-charts';

function HomeAppPage() {
  const navigate = useNavigate();

  const pieChartData = [
    ['Content Type', 'Percentage'],
    ['Images', 40],
    ['Videos', 30],
    ['Text Posts', 20],
    ['Stories', 10],
  ];

  const barChartData = [
    ['Platform', 'Followers'],
    ['Facebook', 5000],
    ['Instagram', 12000],
    ['Twitter', 8000],
    ['LinkedIn', 4000],
  ];

  const lineChartData = [
    ['Month', 'Likes', 'Shares'],
    ['Jan', 1000, 400],
    ['Feb', 1170, 460],
    ['Mar', 660, 1120],
    ['Apr', 1030, 540],
  ];

  const optionsPie = {
    title: 'Content Type Distribution',
    pieHole: 0.4,
    is3D: false,
  };

  const optionsBar = {
    chart: {
      title: 'Followers by Platform',
      subtitle: 'Comparison of follower counts across platforms',
    },
  };

  const optionsLine = {
    title: 'Engagement Over Time',
    curveType: 'function',
    legend: { position: 'bottom' },
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3, maxWidth: 1400, margin: '0 auto' }}>
      {/* Welcome Section */}
      <Box sx={{ marginBottom: 3, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Bienvenue sur votre tableau de bord des réseaux sociaux
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Analysez vos données et optimisez vos performances sur les réseaux sociaux.
        </Typography>
      </Box>

      {/* Main Content */}
      <Grid container spacing={3}>
        {/* Pie Chart */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h6">Répartition des types de contenu</Typography>
            <Chart
              chartType="PieChart"
              width="100%"
              height="300px"
              data={pieChartData}
              options={optionsPie}
            />
          </Paper>
        </Grid>

        {/* Bar Chart */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h6">Abonnés par plateforme</Typography>
            <Chart
              chartType="Bar"
              width="100%"
              height="300px"
              data={barChartData}
              options={optionsBar}
            />
          </Paper>
        </Grid>

        {/* Line Chart */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h6">Engagement au fil du temps</Typography>
            <Chart
              chartType="LineChart"
              width="100%"
              height="300px"
              data={lineChartData}
              options={optionsLine}
            />
          </Paper>
        </Grid>

        {/* Navigation Buttons */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/dashboard/new-post')}
            >
              Créer une nouvelle publication
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/dashboard/calendar')}
            >
              Voir le calendrier
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeAppPage;
