import React from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SocialMediaAuthorizationCard from '../../../../../components/Card/SocialMediaAuthorizationCard';

const LinkedInAuth = () => {
  // Fonction factice pour gérer l'autorisation LinkedIn
  const handleAuthorizeLinkedIn = () => {
    console.log("Simulation d'autorisation LinkedIn en cours...");
    alert("Vous avez simulé une autorisation LinkedIn avec succès !");
  };

  return (
    <SocialMediaAuthorizationCard
      networkName="LinkedIn"
      networkLogo={<LinkedInIcon sx={{ fontSize: 40, color: '#0A66C2' }} />}
      description={
        <>
          Pour publier sur votre profil ou vos pages LinkedIn, nous aurons besoin des autorisations suivantes :
          <ul>
            <li>Publier des mises à jour : <strong>w_member_social</strong></li>
            <li>Lire les informations de profil : <strong>r_liteprofile</strong></li>
            <li>Accéder à l'email : <strong>r_emailaddress</strong></li>
          </ul>
        </>
      }
      onAuthorize={handleAuthorizeLinkedIn}
    />
  );
};

export default LinkedInAuth;
