import React from 'react';
import { Typography, Container, Grid, Box, Card, CardContent } from '@mui/material';
import FacebookAuth from '../../SocialMedia/SocialMediaAuthorization/FacebookAuth';
import InstagramAuth from '../../SocialMedia/SocialMediaAuthorization/InstagramAuth';
import LinkedInAuth from '../../SocialMedia/SocialMediaAuthorization/LinkedInAuth';
import TwitterAuth from '../../SocialMedia/SocialMediaAuthorization/TwitterAuth';
import AICustomizationCard from './Settings/AICustomizationCard';

function SettingsPage() {
  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
      <Typography variant="h3" fontWeight={600} sx={{ mb: 3, textAlign: 'center' }}>
        Paramètres
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 4, textAlign: 'center' }}>
        Personnalisez votre expérience et connectez vos réseaux sociaux.
      </Typography>

      <Grid container spacing={4} direction="column">
        {/* Section IA */}
        <Grid item xs={12}>
          <AICustomizationCard />
        </Grid>

        {/* Section Réseaux Sociaux */}
        <Grid item xs={12}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <CardContent>
              <Typography variant="h5" fontWeight={600} gutterBottom>
                Connexion aux Réseaux Sociaux
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Liez vos comptes Facebook, Instagram, LinkedIn et Twitter pour publier directement depuis l'application.
              </Typography>
            </CardContent>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ minWidth: '300px', maxWidth: '100%' }}>
                    <FacebookAuth />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ minWidth: '300px', maxWidth: '100%' }}>
                    <InstagramAuth />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ minWidth: '300px', maxWidth: '100%' }}>
                    <LinkedInAuth />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ minWidth: '300px', maxWidth: '100%' }}>
                    <TwitterAuth />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SettingsPage;
