import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import SocialMediaAuthorizationCard from '../../../../../components/Card/SocialMediaAuthorizationCard';

const InstagramAuth = () => {
  // Fonction factice pour gérer l'autorisation Instagram
  const handleAuthorizeInstagram = () => {
    console.log("Simulation d'autorisation Instagram en cours...");
    alert("Vous avez simulé une autorisation Instagram avec succès !");
  };

  return (
    <SocialMediaAuthorizationCard
      networkName="Instagram"
      networkLogo={<InstagramIcon sx={{ fontSize: 40, color: '#E4405F' }} />}
      description={
        <>
          Pour publier sur votre compte Instagram, nous aurons besoin des autorisations suivantes :
          <ul>
            <li>Publier du contenu : <strong>instagram_content_publish</strong></li>
            <li>Lire les données : <strong>instagram_basic</strong></li>
            <li>Gérer les interactions : <strong>instagram_manage_comments</strong></li>
          </ul>
        </>
      }
      onAuthorize={handleAuthorizeInstagram}
    />
  );
};

export default InstagramAuth;
