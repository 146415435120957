import { db } from "../../config/firebaseConfig";
import { doc, getDoc, setDoc, updateDoc, collection, getDocs } from "firebase/firestore";

// Fonction pour récupérer les informations du propriétaire depuis Firestore
export const fetchOwnerInfo = async (ownerId) => {
  try {
    const ownerRef = doc(db, "owners", ownerId);
    const ownerDoc = await getDoc(ownerRef);

    if (ownerDoc.exists()) {
      return ownerDoc.data();
    } else {
      console.error("Le propriétaire n'existe pas dans la base de données.");
      return null;
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des informations du propriétaire:", error);
    return null;
  }
};

// Fonction pour récupérer tous les propriétaires depuis Firestore
export const fetchAllOwners = async () => {
  try {
    const ownersCollection = collection(db, "owners");
    const ownersSnapshot = await getDocs(ownersCollection);
    const owners = ownersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return owners;
  } catch (error) {
    console.error("Erreur lors de la récupération des propriétaires:", error);
    return [];
  }
};

// Fonction pour ajouter un nouveau propriétaire dans Firestore
export const addOwnerInfo = async (ownerId, ownerData) => {
  try {
    const ownerRef = doc(db, "owners", ownerId);
    await setDoc(ownerRef, ownerData);
    console.log("Informations du propriétaire ajoutées avec succès.");
  } catch (error) {
    console.error("Erreur lors de l'ajout des informations du propriétaire:", error);
  }
};

// Fonction pour modifier les informations du propriétaire dans Firestore
export const editOwnerInfo = async (ownerId, updatedData) => {
  try {
    const ownerRef = doc(db, "owners", ownerId);
    await updateDoc(ownerRef, updatedData);
    console.log("Informations du propriétaire mises à jour avec succès.");
  } catch (error) {
    console.error("Erreur lors de la mise à jour des informations du propriétaire:", error);
  }
};
