import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import ChatBubble from '@mui/icons-material/Comment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteButton from './DeleteButton';

function CardActionsTopSection({ isEditing, isPublished, onDelete, onEditToggle, onCancelEdit, onSave, prompt }) {
  const [copied, setCopied] = useState(false);
  const [tooltipText, setTooltipText] = useState(
    <Typography variant="h6" color="inherit" sx={{ lineHeight: 1.4 }}>
      {prompt || 'Aucun contenu fourni'}
    </Typography>
  );

  const handleCopyToClipboard = () => {
    if (prompt) {
      navigator.clipboard.writeText(prompt)
        .then(() => {
          setCopied(true);
          setTooltipText(
            <Typography variant="body1" color="success.main" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CheckCircleIcon fontSize="small" />
              Copié !
            </Typography>
          );
          setTimeout(() => {
            setCopied(false);
            setTooltipText(
              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {prompt || 'Aucun contenu fourni'}
              </Typography>
            );
          }, 2000);
        })
        .catch((err) => {
          console.error('Failed to copy:', err);
        });
    }
  };

  return (
    <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 2, display: 'flex', gap: 1 }}>
      {/* Icon with Tooltip */}
      <Tooltip
        title={tooltipText}
        arrow
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10], // Adds space between icon and tooltip
              },
            },
          ],
        }}
      >
        <IconButton
          onClick={handleCopyToClipboard}
          sx={{
            color: copied ? 'success.main' : 'primary.main',
            '&:hover': {
              color: copied ? 'success.dark' : 'primary.dark', // Slightly darken on hover
            },
          }}
        >
          <ChatBubble />
        </IconButton>
      </Tooltip>

      {/* Other action buttons */}
      {isEditing && (
        <IconButton color="secondary" onClick={onCancelEdit}>
          <CancelIcon />
        </IconButton>
      )}
      {isEditing ? (
        <IconButton color="secondary" onClick={onSave}>
          <SaveIcon />
        </IconButton>
      ) : (
        <IconButton color="primary" onClick={onEditToggle}>
          <EditIcon />
        </IconButton>
      )}
      <DeleteButton onClick={onDelete} disabled={isPublished || isEditing} />
    </Box>
  );
}

export default CardActionsTopSection;
