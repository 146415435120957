import React, { useEffect, useMemo, useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import moment from 'moment';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { fetchDraftPostsByDateRange, updateDraftPost, uploadMediaFiles } from '../../../../services/firebase/draftPostService';
import { auth } from '../../../../config/firebaseConfig';
import PostCard from '../../../../components/Card/PostCard/PostCard';
import { fetchCompanyInfo } from '../../../../services/firebase/companyService';
import { useNavigate } from 'react-router-dom';

const localizer = momentLocalizer(moment);

function CalendarPage() {
    const [companyName, setCompanyName] = useState('Nom de l\u2019entreprise');
    const [companyLogoUrl, setCompanyLogoUrl] = useState('');
    const [events, setEvents] = useState([]); // Stocke les brouillons comme événements
    const [visibleRange, setVisibleRange] = useState({ start: null, end: null });
    const [user, setUser] = useState(null); // État pour suivre l'utilisateur Firebase
    const [isLoadingUser, setIsLoadingUser] = useState(true); // État pour savoir si l'utilisateur est en cours de chargement
    const [selectedEvent, setSelectedEvent] = useState(null); // Stocke l'événement sélectionné
    const [isModalOpen, setIsModalOpen] = useState(false); // Gère l'ouverture/fermeture de la modal

    const navigate = useNavigate();

    const calendarConfig = useMemo(
        () => ({
            defaultDate: new Date(), // Date affichée par défaut
            views: [Views.MONTH, Views.WEEK, Views.DAY], // Vues disponibles
            step: 60, // Durée des créneaux (60 minutes ici)
        }),
        []
    );

    const handleRangeChange = async (dates, firebaseUser = user) => {
        if (!firebaseUser) {
            console.warn('Utilisateur non authentifié lors du changement de plage.');
            return;
        }

        const companyData = await fetchCompanyInfo(firebaseUser.uid);
        if (companyData) {
            setCompanyName(companyData.name || 'Nom de l\u2019entreprise');
            setCompanyLogoUrl(companyData.logoUrl || '');
        }

        let startDate, endDate;

        if (Array.isArray(dates)) {
            startDate = dates[0];
            endDate = dates[dates.length - 1];
        } else {
            startDate = dates.start;
            endDate = dates.end;
        }

        setVisibleRange({ start: startDate, end: endDate });

        try {
            const draftPosts = await fetchDraftPostsByDateRange(startDate, endDate);

            const formattedEvents = draftPosts.map((draft) => {
                let start, end;

                if (draft.selectedDate instanceof Date) {
                    start = draft.selectedDate;
                    end = draft.selectedDate;
                } else if (draft.selectedDate?.toDate) {
                    start = draft.selectedDate.toDate();
                    end = draft.selectedDate.toDate();
                } else {
                    start = new Date(draft.selectedDate);
                    end = new Date(draft.selectedDate);
                }

                return {
                    id: draft.id,
                    title: draft.content || 'Brouillon sans titre',
                    start,
                    timestamp: draft.timestamp,
                    selectedDate: draft.selectedDate,
                    end,
                    mediaFiles: draft.mediaUrls || [],
                };
            });

            setEvents(formattedEvents);
        } catch (error) {
            console.error('Erreur lors de la récupération des brouillons :', error);
        }
    };

    const handleEventSelect = (event) => {
        setSelectedEvent(event);
        setIsModalOpen(true);
    };

    const handleSlotSelect = (slotInfo) => {
        const selectedDateTime = slotInfo.start;
        const formattedDateTime = moment(selectedDateTime).format('YYYY-MM-DDTHH:mm');
        navigate(`/dashboard/new-post?selectedDate=${formattedDateTime}`);
    };

    const handleModalClose = () => {
        setSelectedEvent(null);
        setIsModalOpen(false);
    };

    const handleSaveChanges = async (draftId, updatedContent, updatedMediaFiles, addedMediaFiles) => {
        try {
            const uploadedMediaUrls = await uploadMediaFiles(addedMediaFiles);
            const updatedMediaUrls = [
                ...updatedMediaFiles.filter((file) => file.url),
                ...uploadedMediaUrls,
            ];

            await updateDraftPost(draftId, {
                content: updatedContent,
                mediaUrls: updatedMediaUrls,
            });

            handleModalClose();
        } catch (error) {
            console.error('Erreur lors de la sauvegarde des modifications :', error);
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
            setUser(firebaseUser);
            setIsLoadingUser(false);

            if (firebaseUser) {
                const defaultStart = moment().startOf('month').toDate();
                const defaultEnd = moment().endOf('month').toDate();
                handleRangeChange({ start: defaultStart, end: defaultEnd }, firebaseUser);
            } else {
                console.log('Aucun utilisateur connecté.');
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                position: 'relative',
                padding: 2,
            }}
        >
            {isLoadingUser ? (
                <p>Chargement de l'utilisateur...</p>
            ) : user ? (
                <>
                    <Typography
                        variant="h4"
                        component="h1"
                        sx={{ textAlign: 'center', marginBottom: 3 }}
                    >
                        Mon Calendrier
                    </Typography>

                    <Box
                        sx={{
                            flexGrow: 1,
                            padding: 2,
                            marginX: 3,
                            backgroundColor: 'white',
                            borderRadius: 2,
                            boxShadow: 1,
                        }}
                    >
                        <Calendar
                            localizer={localizer}
                            defaultDate={calendarConfig.defaultDate}
                            views={calendarConfig.views}
                            step={calendarConfig.step}
                            style={{ height: '600px' }}
                            selectable
                            onSelectSlot={handleSlotSelect}
                            onRangeChange={(dates) => handleRangeChange(dates)}
                            events={events}
                            onSelectEvent={handleEventSelect}
                        />
                    </Box>
                    <Modal
                        open={isModalOpen}
                        onClose={handleModalClose}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 2,
                        }}
                    >
                        <Box sx={{ maxWidth: 600, width: '100%' }}>
                            {selectedEvent && (
                                <PostCard
                                    companyName={companyName}
                                    companyLogoUrl={companyLogoUrl}
                                    timestamp={selectedEvent.timestamp}
                                    scroll={true}
                                    content={selectedEvent.title}
                                    mediaFiles={selectedEvent.mediaFiles}
                                    selectedDate={selectedEvent.selectedDate}
                                    draftId={selectedEvent.id}
                                    onSave={(updatedContent, updatedMediaFiles, addedMediaFiles) =>
                                        handleSaveChanges(selectedEvent.id, updatedContent, updatedMediaFiles, addedMediaFiles)
                                    }
                                    onClose={handleModalClose}
                                />
                            )}
                        </Box>
                    </Modal>
                </>
            ) : (
                <p>Veuillez vous connecter pour accéder à votre calendrier.</p>
            )}
        </Box>
    );
}

export default CalendarPage;
