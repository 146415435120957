import React from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import SocialMediaAuthorizationCard from '../../../../../components/Card/SocialMediaAuthorizationCard';

const TwitterAuth = () => {
  // Fonction factice pour gérer l'autorisation Twitter
  const handleAuthorizeTwitter = () => {
    console.log("Simulation d'autorisation Twitter en cours...");
    alert("Vous avez simulé une autorisation Twitter avec succès !");
  };

  return (
    <SocialMediaAuthorizationCard
      networkName="Twitter"
      networkLogo={<TwitterIcon sx={{ fontSize: 40, color: '#1DA1F2' }} />}
      description={
        <>
          Pour publier sur votre compte Twitter, nous aurons besoin des autorisations suivantes :
          <ul>
            <li>Lire les tweets : <strong>read</strong></li>
            <li>Publier des tweets : <strong>write</strong></li>
            <li>Accéder à vos abonnements : <strong>follow</strong></li>
          </ul>
        </>
      }
      onAuthorize={handleAuthorizeTwitter}
    />
  );
};

export default TwitterAuth;
