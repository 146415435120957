import React, { useState } from 'react';
import { auth, db, storage } from '../../config/firebaseConfig'; // Firebase Storage ajouté
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Card, CardContent, Container, Alert, Avatar, IconButton } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

function CompanyFormPage() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [workforce, setWorkforce] = useState('');
  const [address, setAddress] = useState('');
  const [sector, setSector] = useState('');
  const [logoPreview, setLogoPreview] = useState(null); // Aperçu du logo
  const [logoFile, setLogoFile] = useState(null); // Fichier du logo
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const uploadFile = async (file, path) => {
    const fileRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(fileRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        (error) => reject(error),
        async () => {
          const downloadURL = await getDownloadURL(fileRef);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleImageUpload = (e, setPreview, setFile) => {
    const file = e.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file)); // Génère un aperçu local
      setFile(file); // Stocke le fichier pour le télécharger plus tard
    }
  };

  const handleSaveCompany = async (e) => {
    e.preventDefault();

    if (!auth.currentUser) {
      setError('Vous devez être connecté pour enregistrer les informations.');
      return;
    }

    try {
      setIsUploading(true);

      const userId = auth.currentUser.uid;
      let logoUrl = '';

      // Téléchargement du logo, si disponible
      if (logoFile) {
        logoUrl = await uploadFile(logoFile, `logos/${userId}`);
      }

      // Sauvegarde des informations dans Firestore
      const companyRef = doc(db, 'companies', userId);
      await setDoc(companyRef, {
        name,
        description,
        website,
        workforce,
        address,
        sector,
        logoUrl, // URL du logo
        ownerId: userId,
      });

      console.log('Informations sur la société enregistrées avec succès.');
      navigate('/dashboard');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des informations:', error);
      setError('Une erreur s\'est produite. Veuillez réessayer.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5, mb: 5 }}>
      <Card sx={{ boxShadow: 3, borderRadius: 4 }}>
        <CardContent>
          <Typography component="h1" variant="h5" align="center" sx={{ mb: 2 }}>
            Informations sur votre société
          </Typography>
          <Box component="form" onSubmit={handleSaveCompany} sx={{ width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nom de la société"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              id="description"
              label="Description"
              name="description"
              multiline
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              id="website"
              label="Site Web"
              name="website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              id="workforce"
              label="Effectif"
              name="workforce"
              value={workforce}
              onChange={(e) => setWorkforce(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              id="address"
              label="Adresse complète"
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              id="sector"
              label="Secteur d'activité"
              name="sector"
              value={sector}
              onChange={(e) => setSector(e.target.value)}
            />

            {/* Avatar pour le logo */}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Typography variant="body1" gutterBottom>
                Logo de la société :
              </Typography>
              <IconButton component="label">
                <Avatar
                  src={logoPreview}
                  sx={{ width: 100, height: 100, cursor: 'pointer' }}
                >
                  <CameraAltIcon />
                </Avatar>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, setLogoPreview, setLogoFile)}
                />
              </IconButton>
            </Box>

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              disabled={isUploading}
            >
              {isUploading ? 'Enregistrement...' : 'Sauvegarder'}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default CompanyFormPage;
