import React from 'react';
import { Button, Typography, Card, CardContent, useMediaQuery, useTheme, Box } from '@mui/material';
import { Link } from 'react-router-dom';

function HomePage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5', // Couleur neutre pour l'arrière-plan
      }}
    >
      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 4,
          textAlign: 'center',
          p: { xs: 2, md: 4 },
          width: isMobile ? '90%' : '400px',
        }}
      >
        <CardContent>
          <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>
            Bienvenue sur Visiblia
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Rejoignez-nous pour découvrir les meilleures solutions pour booster votre présence en ligne.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexDirection: isMobile ? 'column' : 'row' }}>
            <Link to="/signup" style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="primary" fullWidth={isMobile}>
                S'inscrire
              </Button>
            </Link>
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="secondary" fullWidth={isMobile}>
                Se connecter
              </Button>
            </Link>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}

export default HomePage;
