// EditPostModal.js
import React from 'react';
import { Modal, Box, CardContent, useMediaQuery, Backdrop } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingOverlay from './LoadingOverlay';
import MediaPreview from './MediaPreview';
import CompanyInfo from './CompanyInfo';
import CardActionsTopSection from './CardActionsTopSection';
import CardActionsBottomSection from './CardActionsBottomSection';
import PostContent from './PostContent';

function EditPostModal({
  isOpen,
  onClose,
  content,
  mediaFiles,
  companyName,
  companyLogoUrl,
  timestamp,
  onSave,
  onCancelEdit,
  isGenerating,
  isPublishing,
  onAddMedia,
  onRemoveMedia,
  editableContent,
  selectedDate,
  onContentChange,
  onPartialRegenerate,
  isPartialGenerating,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      BackdropComponent={(props) => (
        <Backdrop {...props} onClick={onClose} />
      )}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          width: '90%',
          maxWidth: 800,
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          position: 'relative',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isGenerating || isPublishing ? <LoadingOverlay /> : null}
        <CardActionsTopSection
          isEditing
          onSave={onSave}
          onCancelEdit={onCancelEdit}
        />
        <Box sx={{ flexShrink: 0, mb: 2 }}>
          <CompanyInfo companyName={companyName} companyLogoUrl={companyLogoUrl} timestamp={timestamp} />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            mb: 2,
            pr: 2,
          }}
        >
          <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
            <PostContent
              content={editableContent}
              isGenerating={isGenerating}
              isPartialGenerating={isPartialGenerating}
              isEditing
              onContentChange={onContentChange}
              onPartialRegenerate={onPartialRegenerate}
            />
            <MediaPreview
              mediaFiles={mediaFiles}
              isEditing
              onAddMedia={onAddMedia}
              onRemoveMedia={onRemoveMedia}
            />
          </CardContent>
        </Box>
        <CardActionsBottomSection
          isEditing
          isPublishing={isPublishing}
          onPublishToFacebook={() => {}}
          onSchedule={() => {}}
          selectedDate={selectedDate}
          isMobile={isMobile}
        />
      </Box>
    </Modal>
  );
}

export default EditPostModal;
