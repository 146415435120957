import { db } from "../../config/firebaseConfig";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

// Fonction pour récupérer les informations de l'entreprise depuis Firestore
export const fetchCompanyInfo = async (companyId) => {
  try {
    const companyRef = doc(db, "companies", companyId);
    const companyDoc = await getDoc(companyRef);

    if (companyDoc.exists()) {
      return companyDoc.data();
    } else {
      console.error("La société n'existe pas dans la base de données.");
      return null;
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des informations de la société:", error);
    return null;
  }
};

// Fonction pour ajouter une nouvelle société dans Firestore
export const addCompanyInfo = async (companyId, companyData) => {
  try {
    const companyRef = doc(db, "companies", companyId);
    await setDoc(companyRef, companyData);
    console.log("Informations de la société ajoutées avec succès.");
  } catch (error) {
    console.error("Erreur lors de l'ajout des informations de la société:", error);
  }
};

// Fonction pour modifier les informations de la société dans Firestore
export const editCompanyInfo = async (companyId, updatedData) => {
  try {
    const companyRef = doc(db, "companies", companyId);
    await updateDoc(companyRef, updatedData);
    console.log("Informations de la société mises à jour avec succès.");
  } catch (error) {
    console.error("Erreur lors de la mise à jour des informations de la société:", error);
  }
};
