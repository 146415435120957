import React from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';

const AppBarComponent = ({ onDrawerToggle }) => {
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      sx={{
        paddingTop: 'env(safe-area-inset-top)',
        background: 'linear-gradient(90deg, ' + theme.palette.primary.main + ' 0%, ' + theme.palette.secondary.main + ' 100%)', // Dégradé moderne
        color: theme.palette.common.white, // Texte blanc du thème
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Ombre douce
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawerToggle}
          sx={{
            mr: 2,
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)', // Survol doux
            },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap sx={{ fontWeight: 'bold' }}>
          Visiblia
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
