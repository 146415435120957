import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976D2', // Bleu classique de MUI
    },
    secondary: {
      main: '#9C27B0', // Violet classique pour les accents
    },
    background: {
      default: '#FAFAFA', // Blanc cassé standard
      paper: '#FFFFFF', // Blanc pur pour les surfaces
    },
    text: {
      primary: '#212121', // Noir standard pour le texte principal
      secondary: '#757575', // Gris classique pour le texte secondaire
    },
    info: {
      main: '#2196F3', // Bleu pour les messages d'information
    },
    success: {
      main: '#4CAF50', // Vert classique pour le succès
    },
    error: {
      main: '#F44336', // Rouge classique pour les erreurs
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // MUI par défaut
    h1: {
      fontSize: '2.125rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.875rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    button: {
      fontWeight: 500,
      textTransform: 'uppercase', // MUI par défaut pour les boutons
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase', // Style par défaut
          padding: '8px 16px',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#1565C0', // Bleu plus foncé au survol
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Radius classique de MUI
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          padding: '16px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1976D2',
          color: '#FFFFFF',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px', // Radius classique
        },
        input: {
          padding: '10px 14px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#E0E0E0', // Gris standard MUI
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#1976D2', // Bleu pour la progression
        },
      },
    },
  },
});

export default theme;
