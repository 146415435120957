import React, { useState } from 'react';
import { Tabs, Tab, Box, Container, Typography } from '@mui/material';
import OwnerProfile from './components/OwnerProfile';
import CompanyProfile from './components/CompanyProfile';
import SettingsPage from './components/SettingsPage';

function ProfilePage() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
      <Typography variant="h3" fontWeight={600} sx={{ mb: 3, textAlign: 'center' }}>
        Gestion du Profil
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 4, textAlign: 'center' }}>
        Gérez vos informations personnelles, les détails de votre entreprise et vos paramètres ici.
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="Profile Tabs" centered>
          <Tab label="Profil Personnel" sx={{ textTransform: 'none', fontWeight: 500 }} />
          <Tab label="Entreprise" sx={{ textTransform: 'none', fontWeight: 500 }} />
          <Tab label="Paramètres" sx={{ textTransform: 'none', fontWeight: 500 }} />
        </Tabs>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Box sx={{ maxWidth: 800, width: '100%' }}>
          <TabPanel value={activeTab} index={0}>
            <OwnerProfile />
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <CompanyProfile />
          </TabPanel>

          <TabPanel value={activeTab} index={2}>
            <SettingsPage />
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default ProfilePage;
